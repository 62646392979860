import React, { Component } from 'react';
import Script from 'next/script';
import { stores } from '../src/stores';
import { Provider } from 'mobx-react';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
	dsn: process.env.sentryDSN,
	environment: process.env.env,
	integrations: [new Integrations.BrowserTracing()],
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

import '@anephenix/ui/dist/index.css';
import '../styles/index.scss';
import '../styles/navbar.scss';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { eventId: null, hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<button
					onClick={() =>
						Sentry.showReportDialog({ eventId: this.state.eventId })
					}
				>
					Report feedback
				</button>
			);
		}
		return this.props.children;
	}
}

const metaEntries = [
	{ name: 'viewport', content: 'initial-scale=1, width=device-width' },
	{
		name: 'keywords',
		content:
			'web development, consulting, product labs, node.js, react, realtime',
	},
	{
		name: 'description',
		content: 'A digital studio for product development and consulting',
	},
	{ name: 'og:title', content: 'Anephenix' },
	{
		name: 'og:description',
		content: 'A digital studio for product development and consulting',
	},
	{ name: 'og:image', content: 'https://anephenix.com/social-image.png' },
	{ name: 'theme-color', content: '#fff' },
];

const linkEntries = [
	{
		rel: 'apple-touch-icon',
		sizes: '180x180',
		type: 'image/png',
		href: '/apple-touch-icon.png',
	},
	{
		rel: 'icon',
		sizes: '32x32',
		type: 'image/png',
		href: '/favicon-32x32.png',
	},
	{
		rel: 'icon',
		sizes: '16x16',
		type: 'image/png',
		href: '/favicon-16x16.png',
	},
	{ rel: 'manifest', href: '/site.webmanifest' },
];

const HeadContent = () => (
	<>
		<Head>
			{metaEntries.map((metaEntry, index) => (
				<meta key={index} {...metaEntry} />
			))}
			{linkEntries.map((linkEntry, index) => (
				<link key={index} {...linkEntry} />
			))}
			<title>Anephenix</title>
		</Head>
		<Script src='/service-worker-install.js' strategy='beforeInteractive' type='module' />
		<Script
			async
			type='text/javascript'
			data-domain="anephenix.com"
			src="https://plausible.io/js/plausible.js"
			strategy='afterInteractive'
		/>
	</>
);

function MyApp({ Component, pageProps }) {
	return (
		<ErrorBoundary>
			<Provider {...stores}>
				<HeadContent />
				<Component {...pageProps} setShowCookieChoices={false} />
			</Provider>
		</ErrorBoundary>
	);
}

export default MyApp;
